<template>
  <div class="loginBackground">
    <div class="loginContainer">
      <img alt="" class="loginLogo" src="../../assets/1688/loginLogo.png" @click="$fun.routerToPage('/')">
      <div class="title">ログイン</div>
      <div>
        <!-- 账号 -->
        <div :class="loginNameFocusStatus===true?'newInput':'input'" style="margin: 33px 0 40px">
          <div class="label">{{ $fanyi("账号") }}</div>
          <el-input v-model="loginData.login_name"
                    :placeholder="$fanyi('请输入账号或手机号或邮箱')"
                    @blur="loginNameBlur" @focus="loginNameFocus"/>
        </div>
        <!-- 密码 -->
        <div :class="passwordFocusStatus===true?'newInput':'input'" style="margin-bottom: 28px">
          <div class="label">{{ $fanyi("密码") }}</div>
          <el-input v-model="loginData.password" :placeholder="$fanyi('请输入密码')" :type="inputType"
                    style="position: relative" @blur="passwordBlur"
                    @focus="passwordFocus"/>
          <img :src="passwordIcon" class="mimaIcon" @click="updatePasswordIcon">
        </div>
        <!--登录  -->
        <div class="submit">
          <el-button type="primary" @click.stop="login">{{ $fanyi("登录") }}</el-button>
        </div>
        <!--      忘记密码与注册-->
        <div class="operationContainer">
          <div class="flexAndCenter">
            <input v-model="Save_the_password"
                   style="margin-right:4px"
                   type="checkbox"
            />
            <div class="text" style="margin-top: -2px">{{ $fanyi("保持登录状态") }}</div>
          </div>
          <div class="flexAndCenter">
            <div class="forgetThePassword" style="margin-right: 20px" @click="forgetThePasswordPageJump">
              {{ $fanyi("忘记密码") }}
            </div>
            <div @click="$fun.routerToPage('/register')">{{
                $fanyi("注册")
              }}
            </div>
          </div>
        </div>
        <div class="technicalServiceSupport">1688 より、一部技術、情報サポートを受けて提供させて頂いております</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Save_the_password: false,
      loginData: {
        login_name: localStorage.getItem("jpusername") ? localStorage.getItem("jpusername") : "",
        password: localStorage.getItem("jppassword") ? localStorage.getItem("jppassword") : "",
      },
      loginNameFocusStatus: false,
      passwordFocusStatus: false,
      inputType: 'password',
      passwordStatus: false,
      passwordIcon: require('../../assets/mima-2.png'),
    };
  },
  created() {
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.login(); // 登录方法名
        return false;
      }
    };
    localStorage.getItem("jpkeep") == 1 ? (this.Save_the_password = true) : (this.Save_the_password = false);
  },
  methods: {
    login() {
      if (this.loginData.login_name === '') {
        this.$message.error(this.$fanyi("请输入账号或手机号或邮箱"));
        return
      }
      if (this.loginData.password === '') {
        this.$message.error(this.$fanyi("请输入密码"));
        return
      }
      // 记住密码
      if (this.Save_the_password == true) {
        localStorage.setItem("jpusername", this.loginData.login_name);
        localStorage.setItem("jppassword", this.loginData.password);
        localStorage.setItem("jpkeep", 1);
      } else {
        localStorage.removeItem("jpusername");
        localStorage.removeItem("jppassword");
        localStorage.removeItem("jpkeep");
      }
      this.$api.logins({
        account: this.loginData.login_name,
        password: this.loginData.password,
        client_tool: 1,
      }).then((res) => {
        if (!res.success) {
          this.$confirm(
              res.msg,
              '注意',
              {
                confirmButtonText: this.$fanyi('确定'), //确定
                cancelButtonText: this.$fanyi('取消'), //取消
                type: "warning",
                center: true,
                dangerouslyUseHTMLString: true,
              }
          ).then(() => {

          }).catch(() => {

          });
          return
        }
        localStorage.setItem("japan_user_token", res.data.userToken);
        this.ordersSum();
        this.logisticsSum();
        this.porderSum();
        this.warehouseSum();
        this.$api.japanGetUserInfo().then((res) => {
          this.$store.commit("userData", res.data);
          if (this.$store.state.page === '') {
            if (res.data.operation_id == '1648') {
              this.$fun.routerToPage("/user/index");
            } else {
              this.$fun.routerToPage("/");
            }
          } else {
            switch (this.$store.state.page) {
              case 'cart':
                window.open("/cart")
                break;
              case 'porder':
                window.open("/user/deliveryList")
                break;
              case 'chat':
                this.$fun.routerToPage("/");
                this.chatPageJump();
                break;
              case 'warehouse':
                window.open("/warehouse")
                break;
              case 'issueOrder':
                window.open("/user/issueOrder")
                break;
              case 'order':
                window.open("/user/order")
                break;
              case 'personalCenter':
                window.open("/user/index")
                break;
            }
            this.$forceUpdate();
          }
        });
      });
    },
    async chatPageJump() {
      if (location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) {
        // if (this.$store.state.userInfo == null || (this.$store.state.userInfo != null && this.$store.state.userInfo.chatwork == '')) {
        let user_token = localStorage.getItem('japan_user_token')
        this.loading = this.$loading({
          lock: true,
          spinner: "el-icon-loading fontSize50",
          background: "rgba(255, 255, 255, 0.7)",
        });
        await this.$api.userTimGroupCheck({user_id: this.$store.state.userInfo.operation_id}).then((res) => {
          this.loading.close();
          var a = document.createElement("a");
          a.setAttribute("href", `https://rakuchat.rakumart.com/client_web/#/?id=${this.$store.state.userInfo.operation_id}&token=${user_token}`);
          a.setAttribute("target", '_blank')
          let clickEvent = document.createEvent("MouseEvents");
          clickEvent.initEvent("click", true, true);
          a.dispatchEvent(clickEvent);
        });
        // } else {
        //   var a = document.createElement("a");
        //   a.setAttribute("href", `https://www.chatwork.com/#!rid' + ${this.$store.state.userInfo.chatwork}`);
        //   a.setAttribute("target", '_blank')
        //   let clickEvent = document.createEvent("MouseEvents");
        //   clickEvent.initEvent("click", true, true);
        //   a.dispatchEvent(clickEvent);
        // }
      }
    },
    //更新输入框密码展示状态
    updatePasswordIcon() {
      this.passwordStatus = !this.passwordStatus;
      this.passwordStatus === true ? this.inputType = 'text' : this.inputType = 'password';
      this.passwordStatus === true ? this.passwordIcon = require('../../assets/mima-1.png') : this.passwordIcon = require('../../assets/mima-2.png');
    },
    //登录名获得焦点
    loginNameFocus() {
      this.loginNameFocusStatus = true;
    },
    //登录名失去焦点
    loginNameBlur() {
      this.loginNameFocusStatus = false;
    },
    //密码获得焦点
    passwordFocus() {
      this.passwordFocusStatus = true;
    },
    //密码名失去焦点
    passwordBlur() {
      this.passwordFocusStatus = false;
    },
    //获取中国国内物流各个状态的数量
    logisticsSum() {
      this.$api.chinaLogisticsListTableNum().then((res) => {
        if (res.code != 0) {
          return
        }
        this.$store.commit("setChinaLogisticsListTableNumList", res.data);
      })
    },
    //获取配送单各个状态数量
    porderSum() {
      this.$api.storageDeliverNum().then((res) => {
        if (res.code != 0) {
          return
        }
        this.$store.commit("setStorageDeliverNumList", res.data);
      })
    },
    //获取仓库各个状态的数量
    warehouseSum() {
      this.$api.stockTableNum().then((res) => {
        if (res.code != 0) {
          return
        }
        this.$store.commit("setWarehouseSumList", res.data);
      })
    },
    //获取订单列表各个状态数量
    ordersSum() {
      this.$api.ordersSum().then((res) => {
        if (res.code != 0) {
          let arr = [];
          this.$api.problemGoodsOrderNum().then((res) => {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].name === '待回复') {
                arr.push(res.data[i]);
                this.$store.commit("setOrdersSumList", arr);
                break;
              }
            }
          });
        } else {
          this.$store.commit("setOrdersSumList", res.data);
        }
      })
    },
    //忘记密码页面跳转
    forgetThePasswordPageJump() {
      let href = "/ForgetThePassword";
      window.open(href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin.scss";

.loginBackground {
  width: 100%;
  height: 100vh;
  background: url("../../assets/1688/loginBackground.png") no-repeat;
  background-size: 100% 100%;
  position: relative;

  .loginContainer {
    position: absolute;
    top: 15%;
    right: 20%;
    width: 520px;
    height: 596px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 18px;
    padding: 40px 30px 30px;

    .loginLogo {
      margin-left: 70px;
      height: 39px;
    }

    .title {
      margin-top: 40px;
      color: #000000;
      font-size: 24px;
      padding-bottom: 5px;
      border-bottom: 3px solid #000000;
      width: 96px;
    }

    .newInput {
      position: relative;
      width: 460px !important;

      .label {
        font-size: 16px;
        margin: 0 0 9px 8px;
        color: #B4272B;
      }

      /deep/ .el-input__icon {
        font-size: 25px;
        margin: 3px 10px 0 0;
      }

      /deep/ .el-input__inner {
        border-radius: 6px;
        height: 40px;
        border-color: #B4272B;
      }
    }

    .input {
      position: relative;
      width: 460px !important;

      .label {
        font-size: 16px;
        margin: 0 0 9px 8px;
        color: #333333;
      }

      /deep/ .el-input__icon {
        font-size: 25px;
        margin: 3px 10px 0 0;
      }

      /deep/ .el-input__inner {
        border-radius: 6px;
        height: 40px;
        border-color: #CCCCCC;
      }
    }

    .mimaIcon {
      width: 22px;
      height: 16px;
      position: absolute;
      top: 42px;
      right: 20px;
    }

    .submit {
      width: 460px;

      .el-button {
        width: 100%;
        border-radius: 6px;
        height: 40px;
        font-size: 18px;
        background: #FF4000;
        border: none;
      }
    }

    .operationContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      div {
        cursor: pointer;
        font-size: 14px;
        color: #666666;
      }
    }

    .technicalServiceSupport {
      text-align: center;
      color: #999999;
      font-size: 13px;
      margin-top: 68px;
    }
  }
}

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}
</style>
